import axios from 'axios'

// const basePath = "http://localhost:9111"; Local development
const basePath = "https://h387pw84f7.execute-api.ap-south-1.amazonaws.com/v1";

const reportBasePath = "http://13.126.214.161/mobigents-rest";


export const get = async (url) => new Promise((res, rej) => {
    const config =localStorage.getItem("token")? {
        headers: { Authorization: `${localStorage.getItem("token")}` }
    }:{};
    axios.get(basePath + url,config)
        .then(response => {
            const data = response.data;
            res(data)
        })
        .catch(error => {
            console.log(error);
            res(null)
        })
});


export const post = async (url, data) => new Promise((res, rej) => {
    const config =localStorage.getItem("token")? {
        headers: { Authorization: `${localStorage.getItem("token")}` }
    }:{};
    axios.post(basePath +url, data,config)
        .then(response => {
            res(response.data)
        })
        .catch(error => {
            console.log(error);
            res(null)
        })
});

export const put = async (url, data) => new Promise((res, rej) => {
    const config =localStorage.getItem("token")? {
        headers: { Authorization: `${localStorage.getItem("token")}` }
    }:{};
    axios.put(basePath +url, data,config)
        .then(response => {
            res(response.data)
        })
        .catch(error => {
            console.log(error);
            res(null)
        })
});

export const deleteRecord = async (url, data) => new Promise((res, rej) => {
    const config =localStorage.getItem("token")? {
        headers: { Authorization: `${localStorage.getItem("token")}` }
    }:{};
    axios.delete(basePath +url,config)
        .then(response => {
            res(response.data)
        })
        .catch(error => {
            console.log(error);
            res(null)
        })
});

export const REPORT_BASE_PATH = reportBasePath;
export const API_BASE_PATH = basePath;

