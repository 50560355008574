import React from 'react';
import DeckGL from '@deck.gl/react';
import {ScenegraphLayer} from '@deck.gl/mesh-layers';
import type {PickingInfo} from '@deck.gl/core';
import {Map as ReactMap} from 'react-map-gl';

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGhhbmVlc2h0bmFpciIsImEiOiJjajN2OXU3b2IwMTRuMnlsYmRhandwdWV0In0.PhXxSGpmy4fXeO4tbfQ9Yg';

type BartStation = {
  name: string;
  coordinates: [longitude: number, latitude: number];
};

export function SG() {
  const layer = new ScenegraphLayer<BartStation>({
    id: 'ScenegraphLayer',
    data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-stations.json',
    
    getPosition: (d: BartStation) => d.coordinates,
    getOrientation: (d: BartStation) => [0, Math.random() * 180, 90],
    scenegraph: '/bus.glb',
    sizeScale: 200,
    _animations: {
      '*': {speed: 100}
    },
    _lighting: 'pbr',
    pickable: true
  });

  return <DeckGL
    initialViewState={{
      longitude: -122.4,
      latitude: 37.74,
      zoom: 11,
      pitch: 60,
      maxPitch: 60,
      bearing: 0,
      minZoom: 2,
      maxZoom: 30,
    }}
    controller
    getTooltip={({object}: PickingInfo<BartStation>) => object && object.name as any}
    layers={[layer]}
  >


<ReactMap

reuseMaps
mapStyle={"mapbox://styles/mapbox/navigation-day-v1"}// "mapbox://styles/mapbox/navigation-night-v1"}
mapboxAccessToken={MAPBOX_TOKEN}></ReactMap>
  </DeckGL>;
}