// import React from 'react';
// import {IconLayer} from '@deck.gl/layers';
// import type {PickingInfo} from '@deck.gl/core';
// import DeckGL from '@deck.gl/react';


// type BartStation = {
//   name: string;
//   entries: number;
//   exits: number;
//   coordinates: [longitude: number, latitude: number];
// };

// export function Visualizer() {
//   const layer = new IconLayer<BartStation>({
//     id: 'IconLayer',
//     data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-stations.json',
//     getColor: (d: BartStation) => [Math.sqrt(d.exits), 140, 0],
//     getIcon: (d: BartStation) => 'marker',
//     getPosition: (d: BartStation) => d.coordinates,
//     getSize: 40,
//     iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
//     iconMapping: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.json',
//     pickable: true
//   });

//   return <DeckGL
//     initialViewState={{
//       longitude: -122.4,
//       latitude: 37.74,
//       zoom: 11
//     }}
//     controller
//     getTooltip={({object}: PickingInfo<BartStation>) => object && object.name as any}
//     layers={[layer]}
//   />;
// }

import React, {useEffect, useState} from 'react';
import DeckGL from '@deck.gl/react';
import {Tile3DLayer} from '@deck.gl/geo-layers';
import {CesiumIonLoader, Tiles3DLoader} from '@loaders.gl/3d-tiles';
import type {MapViewState, PickingInfo} from '@deck.gl/core';
import type {Tileset3D} from '@loaders.gl/tiles';
import {Map} from 'react-map-gl/maplibre';
import {Map as ReactMap} from 'react-map-gl';

import {TerrainLayer, TerrainLayerProps} from '@deck.gl/geo-layers';
import { COORDINATE_SYSTEM, IconLayer, PointCloudLayer, ScenegraphLayer } from 'deck.gl';
import { post } from '../Api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import Sidebar from './Sidebar'


const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGhhbmVlc2h0bmFpciIsImEiOiJjajN2OXU3b2IwMTRuMnlsYmRhandwdWV0In0.PhXxSGpmy4fXeO4tbfQ9Yg';



const TERRAIN_IMAGE = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${MAPBOX_TOKEN}`;
const SURFACE_IMAGE = `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_TOKEN}`;

const ELEVATION_DECODER: TerrainLayerProps['elevationDecoder'] = {
    rScaler: 6553.6,
    gScaler: 25.6,
    bScaler: 0.1,
    offset: -10000
  };

  
const smVariant = { navigation: 'drawer', navigationButton: true }
const mdVariant = { navigation: 'sidebar', navigationButton: false }

  
export function Visualizer() {


  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const variants = useBreakpointValue({ base:  mdVariant })

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

  const [loaded,setLoaded] = useState(false);
  const [initialViewState, setInitialViewState] = useState<MapViewState>({
    longitude: 0,
      latitude: 0,
    

      pitch: 60,
  maxPitch: 60,
  bearing: 0,
  minZoom: 2,
  maxZoom: 30,
  zoom: 15

  });

  type BartStation = {
  name: string;
  entries: number;
  exits: number;
  coordinates: [longitude: number, latitude: number];
};

var userInfo:any = localStorage.getItem("userInfo");
if (userInfo) {
    userInfo = JSON.parse(userInfo);
}

  // const layer = new Tile3DLayer({
  //   id: 'tile-3d-layer',
  //   data: 'output_data/tileset.json',
  //   loader:Tiles3DLoader,
  //   pickable:true,
   
   

  //       onTilesetLoad: (tileset: Tileset3D) => {
  //       // Recenter to cover the tileset
  //       const {cartographicCenter, zoom} = tileset as any;
  //       setInitialViewState({
  //           ...initialViewState,
  //         longitude: cartographicCenter[0],
  //         latitude: cartographicCenter[1],
  //         zoom:tileset.zoom
  //       });
  //     },
    
  // });
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedItem,setSelectedItem] = useState(null);


  function BasicUsage({selectedItem}:{selectedItem:any}) {
    const { isOpen, onOpen, onClose } = useDisclosure()




    useEffect(()=>{

      if(selectedItem){
        onOpen()
      }

    },[selectedItem])
    return (
      <>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button variant='ghost'>Secondary Action</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  const fetchDataOC=async (page:number,filter:any) =>{

    var multiFilter ={
        "condition":"AND",
        "rules":[
          {
            "field": "vehicleNumber",
            "type": "string",
            "operator": "!=",
            "value": ""
          }
        ]
      }
    const filt = filter?{searchFilter:{
        vehicleNumber:filter,
        role:"user",
        phone:filter,
        multiFilter:multiFilter
    }}:{role:"user",multiFilter:multiFilter}
    const response = await post(`/objects/zrider_pa/search?partnerId=${userInfo.partnerId}&count=1000&offset=${(page-1)*100}`,filt);
    const records:any[] = response.rows.map((s:any)=>{
        s.data.id=s.dataKey;
        s.data.lastAccessDate=+s.lastAccessDate;
       
        return s.data;
    }).filter((d:any)=>d.latitude && d.longitude);
    
    return records;
}

const modelMapping:any = {
  car: "/mercedes_glb_amg.glb",
  auto: "/auto_rickshaw.glb",
  other: "/mercedes_glb_amg.glb",
  truck: "/mercedes_glb_amg.glb",
  minibus: "/mercedes_glb_amg.glb",
  suv: "/mercedes_glb_amg.glb",


};

  const [layers,setLayers] = useState<any>()

     
      const getStatusColor=(d:any)=>{
        var cTime=new Date().getTime();
        var colour = (cTime - (d.lastAccessDate||0)) > 300000 ? [255,0,0] : [0,255,80]
        let isBusy = (cTime-(d.lastConnectTime||0))<15*60*1000;
        if(d.operationStatus=="stop"){
          colour =  [255,0,0]
        }else if(isBusy) {
          colour =  [0,0,255]
        }

        if(d.selected|| (selectedItem && d.id==(selectedItem as any).id)){
          colour =  [0,255,255]
        }

        
        d.statusColour=colour;
        return d.statusColour;

      }

  

      const fetchUsers = async (page:number,filt:any={}) => {
        setLoading(true);
        const data= await fetchDataOC(page,filt);


        setData(data)


       
    };

      useEffect(() => {
        

        fetchUsers(1);

       
      
    }, []);


    useEffect(()=>{

      if(data && data.length>0){

        const layer = new IconLayer({
          id: 'IconLayer',
          data:data,
          
          /* props from IconLayer class */
          
          // alphaCutoff: 0.05,
          // billboard: true,
          // getAngle: 0,
          getColor: d => getStatusColor(d),
          getIcon: d => 'marker',
          // getPixelOffset: [0, 0],
          getPosition: (d: any) => [d.longitude,d.latitude],
          getSize: 40,

          
          iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
          iconMapping: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.json',
          // onIconError: null,
          // sizeMaxPixels: Number.MAX_SAFE_INTEGER,
          // sizeMinPixels: 0,
          // sizeScale: 1,
          // sizeUnits: 'pixels',
          // textureParameters: null,
          
          /* props inherited from Layer class */
          
          // autoHighlight: false,
          // coordinateOrigin: [0, 0, 0],
          // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
          // highlightColor: [0, 0, 128, 128],
          // modelMatrix: null,
          // opacity: 1,
          pickable: true,
          onClick:(e)=>{
            if(e.object){
            setSelectedItem(e.object)
            const rs=[...data];
            rs.forEach(r=>{
              if(r.id==e.object.id){
                r.selected=true;
              }else{
                r.selected=false;
              }
            })
            setData(rs);
            setSidebarOpen(true)
          }
          }
          // visible: true,
          // wrapLongitude: false,
        });

        // const layer = new ScenegraphLayer<BartStation>({
        //   id: 'ScenegraphLayer',
        //   data: data ,//'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-stations.json',
          
        //   getPosition: (d: any) => [d.longitude,d.latitude],
        //   getOrientation: (d: any) => [0, 180, 90],
        //    scenegraph:'/auto_rickshaw.glb',
        //  // scenegraph: (d:any) => modelMapping[d.type], // Dynamically load the model based on type

        //  // scenegraph: (d:any)=>d.vehicleType=='auto'?'/auto_rickshaw.glb':'/mercedes_glb_amg.glb',
        //   getScale: (d:any)=>d.vehicleType=='auto'?[10,10,10]:[1,1,1],
        //   getTranslation:[4,4,0],
        //   _animations: {
        //     '*': {speed: 100}
        //   },
        //   _lighting: 'pbr',
        //   pickable: true
        // });

     
        setLayers([layer])
       
        if(!loaded){
          setInitialViewState({...initialViewState,latitude:data[0].latitude,longitude:data[0].longitude})
          setLoaded(true);
        }
        
        setLoading(false);


      }

    },[data])

    useEffect(() => {
      // alert("Calling")
      

      
      if(loaded){
      setInterval(()=>{
        console.log("fired .....")
        fetchUsers(1);
      },10000)
    }
    
  }, [loaded]);




      


//   const layer = new Tile3DLayer({
//     id: 'tile-3d-layer',
//     // Tileset json file url
//     data: 'http://localhost:8080/output_data/tileset.json',
//     loader: CesiumIonLoader as any,
//     loadOptions: {
//       // Set up Ion account: https://cesium.com/docs/tutorials/getting-started/#your-first-app
//       'cesium-ion': {accessToken: '1000'}
//     },
//     onTilesetLoad: (tileset: Tileset3D) => {
//       // Recenter to cover the tileset
//       const {cartographicCenter, zoom} = tileset as any;
//       setInitialViewState({
//         longitude: cartographicCenter[0],
//         latitude: cartographicCenter[1],
//         zoom
//       });
//     },
//     pointSize: 2
//   });

const [style,setStyle] = useState<string>("mapbox://styles/mapbox/satellite-v9");
const [type,setType] = useState<string>("satellite");

const onselect=(type:string)=>{
    setType(type);
    if(type=='satellite'){
        setStyle("mapbox://styles/mapbox/satellite-v9")
    }else{
        setStyle("mapbox://styles/mapbox/navigation-night-v1")
    }
}
  return <div className='relative'>

{
  // selectedItem && <BasicUsage selectedItem={selectedItem}></BasicUsage>
  
  <Sidebar
        selectedItem={selectedItem}
        variant={"drawer"}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      />
     
}
<div style={{background:"#00000080"}} className='absolute  left-5 w-[250px] h-auto max-h-[600px]  z-10 p-2 flex flex-col gap-2 mt-[80px] rounded-lg  overflow-auto'>

{

data && data.map(d=>{
  return <div style={{background:"#000000f0"}}

  onClick={(e)=>{

    console.log(d);
    setInitialViewState({
      ...initialViewState,
      zoom:18,
      latitude : d.latitude,
      longitude  : d.longitude
            });

            setSidebarOpen(true)
            setSelectedItem(d);
            const rs=[...data];
            rs.forEach(r=>{
              if(r.id==d.id){
                r.selected=true;
              }else{
                r.selected=false;
              }
            })
            setData(rs);

  }}
  
  
  className="text-center rounded-lg text-xs font-normal  cursor-pointer p-2 shadow-lg flex-col flex text-white">
  <span>{d.name}</span>

  <span>{d.vehicleNumber}</span>

  </div>

})

}
  

 



</div>

  <div className='absolute right-0 w-[150px] h-auto bg-white z-10 p-2 flex flex-col gap-2 mt-[80px] rounded-lg '>

   {
     ["satellite","navigation"].map((s)=>{
        return <h2 className={`${type==s?"bg-teal-500":"bg-white"} text-center rounded-lg text-xs font-normal  cursor-pointer p-2 shadow-lg`} onClick={(e)=>onselect(s)}>{s.toUpperCase()}</h2>
     })
   }


  </div>
  
  <DeckGL
    initialViewState={initialViewState}
    controller
        getTooltip={({object}: PickingInfo<BartStation>) => object && object.name as any}
    layers={layers}
  >

<ReactMap

        reuseMaps
        mapStyle={style}// "mapbox://styles/mapbox/navigation-night-v1"}
        mapboxAccessToken={MAPBOX_TOKEN}
      />
{/* <Map reuseMaps mapStyle={MAP_STYLE} /> */}

  </DeckGL>
  </div>
}