
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

import bgmImage from './images/back1.png'
import apple from './images/apple.png'

import facebook from './images/facebook.png'
import google from './images/google.png'
import './Login.scss';
import { post } from "./Api";

const Login = ({ setUser }) => {

    const { useState } = React;
    let navigate = useNavigate();


    const [inputs, setinputs] = useState({
        email: "",
        password: "",
        partnerId:""
    });

    const [warnemail, setwarnemail] = useState(false);
    const [warnpass, setwarnpass] = useState(false);
    const [danger, setdanger] = useState(true);

    const [eye, seteye] = useState(true);
    const [pass, setpass] = useState("password");


    const inputEvent = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name == "email") {
            if (value.length > 0) {
                setdanger(true);
            }
        }
        setinputs((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        setwarnemail(false);
        setwarnpass(false);
        if (inputs.email.length < 1) { 
            setdanger(false);
        } 
        if (inputs.email == "") {
             setwarnemail(true); 
        } else if (inputs.password == "") { 
            setwarnpass(true); 
        } else { 
            handleOnlogin(inputs.email,inputs.password,inputs.partnerId||"zrider");
        }
    }; 


  async function handleOnlogin(username,password,partnerId) {
    const r =await post("/accounts/login?partnerId="+partnerId,{
        username,password,partnerId
    })
    if (r) {
      let user = r.account;
      localStorage.setItem("userInfo", JSON.stringify(user));
      localStorage.setItem("token", r.token);
      setUser(user)
      navigate("/home");
    } else {
      alert("Username or password incorrect");
    }
  }

  





    const Eye = () => {
        if (pass == "password") {
            setpass("text");
            seteye(false);
        } else {
            setpass("password");
            seteye(true);
        }
    };

    return (
        <>
            <div className="container">
                <div className="card">
                    <div className="form">
                        <div className="left-side">
                            <img src={bgmImage} style={{objectFit:"contain",background:"#fff"}}/>
                        </div>

                        <div className="right-side">
                            <div className="register">
                                <p>Not a member? <a href="#">Register Now</a></p>
                            </div>

                            <div className="hello">
                                <h2>Hello Again!</h2>
                                <h4>Welcome back you have been missed! </h4>
                            </div>

                            <form onSubmit={submitForm}>

                                <div className="input_text">
                                    <input className={` ${warnemail ? "warning" : ""}`} type="text" placeholder="Enter Email" name="email" value={inputs.email} onChange={inputEvent} />
                                    <p className={` ${danger ? "danger" : ""}`}><i className="fa fa-warning"></i>Please enter a valid email address.</p>
                                </div>
                                <div className="input_text">
                                    <input className={` ${warnpass ? "warning" : ""}`} type={pass} placeholder="Enter Password" name="password" value={inputs.password} onChange={inputEvent} />
                                    <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                <div className="recovery">
                                    <p>Recovery Password</p>
                                </div>
                                <div className="btn">
                                    <button type="submit">Sign in</button>
                                </div>

                            </form>

                            <hr />
                            <div className="or">
                                <p>or signin with</p>
                            </div>
                            <div className="boxes">
                                <span><img src={google} /></span>
                                <span><img src={apple} /></span>
                                <span><img src={facebook} /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Login;