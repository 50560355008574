/* global fetch, setTimeout, clearTimeout */
import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {Map} from 'react-map-gl/maplibre';
import DeckGL from '@deck.gl/react';
import {ScenegraphLayer} from '@deck.gl/mesh-layers';

import type {ScenegraphLayerProps} from '@deck.gl/mesh-layers';
import type {PickingInfo, MapViewState} from '@deck.gl/core';
import { post } from '../Api';

// Data provided by the OpenSky Network, http://www.opensky-network.org
const DATA_URL = 'https://opensky-network.org/api/states/all';
// For local debugging
// const DATA_URL = './all.json';
const MODEL_URL =
  'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/scenegraph-layer/airplane.glb';
const REFRESH_TIME_SECONDS = 60;
const DROP_IF_OLDER_THAN_SECONDS = 120;

const ANIMATIONS: ScenegraphLayerProps['_animations'] = {
  '*': {speed: 1}
};
var x=0;
const INITIAL_VIEW_STATE: MapViewState = {
  latitude: 39.1,
  longitude: -94.57,
  zoom: 3.8,
  maxZoom: 16,
  pitch: 0,
  bearing: 0
};

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json';

// https://openskynetwork.github.io/opensky-api/rest.html#response
type Vandy = [
  id: string,
  name: string,
  updatedTime: number,
  longitude: number | null,
  latitude: number | null,
  altitude: number | null,
  driverImage:string,
  category: number
];

const DATA_INDEX = {
  UNIQUE_ID: 0,
  NAME: 1,
  UPDATED_TIME: 2,
  LONGITUDE: 3,
  LATITUDE: 4,
  ALTITUDE: 5,
  DRIVER_IMAGE: 6,
  CATEGORY: 7,
} as const;

async function fetchData(): Promise<Vandy[]> {
  const resp = await fetch(DATA_URL);
  const {time, states} = (await resp.json()) as {time: number; states: Vandy[]};
  // make lastContact timestamp relative to response time
//   for (const a of states) {
//     a[DATA_INDEX.LAST_CONTACT] -= time;
//   }
  return states;
}

function getTooltip({object}: PickingInfo<any>) {
  return (
    object &&
    `\
    NAME: ${object.name || ''}
    `

    // Country: ${object[DATA_INDEX.ORIGIN_COUNTRY] || ''}
    // Vertical Rate: ${object[DATA_INDEX.VERTICAL_RATE] || 0} m/s
    // Velocity: ${object[DATA_INDEX.VELOCITY] || 0} m/s
    // Direction: ${object[DATA_INDEX.TRUE_TRACK] || 0}
  );
}

export default function Live({
  sizeScale = 25,
  onDataLoad,
  mapStyle = MAP_STYLE
}: {
  sizeScale?: number;
  onDataLoad?: (count: number) => void;
  mapStyle?: string;
}) {
//   const [data, setData] = useState<Vandy[]>();
  const [timer, setTimer] = useState<{id: any }>({id: 0});


  const [initialViewState, setInitialViewState] = useState<MapViewState>({
    longitude: -122.4,
      latitude: 37.74,
    

      pitch: 60,
  maxPitch: 60,
  bearing: 0,
  minZoom: 2,
  maxZoom: 30,
  zoom: 10

  });
  // const layer = new Tile3DLayer({
  //   id: 'tile-3d-layer',
  //   data: 'output_data/tileset.json',
  //   loader:Tiles3DLoader,
  //   pickable:true,
   
   

  //       onTilesetLoad: (tileset: Tileset3D) => {
  //       // Recenter to cover the tileset
  //       const {cartographicCenter, zoom} = tileset as any;
  //       setInitialViewState({
  //           ...initialViewState,
  //         longitude: cartographicCenter[0],
  //         latitude: cartographicCenter[1],
  //         zoom:tileset.zoom
  //       });
  //     },
    
  // });
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchDataOC=async (page:number,filter:any) =>{


  var userInfo:any = localStorage.getItem("userInfo");
  if (userInfo) {
      userInfo = JSON.parse(userInfo);
  }

    var multiFilter ={
        "condition":"AND",
        "rules":[
          {
            "field": "vehicleNumber",
            "type": "string",
            "operator": "!=",
            "value": ""
          }
        ]
      }
    const filt = filter?{searchFilter:{
        vehicleNumber:filter,
        role:"user",
        phone:filter,
        multiFilter:multiFilter
    }}:{role:"user",multiFilter:multiFilter}
    const response = await post(`/objects/zrider_pa/search?partnerId=${userInfo.partnerId}&count=1000`,filt);
    const records:any[] = response.rows.map((s:any)=>{
        s.data.id=s.dataKey;
        return s.data;
    }).filter((d:any)=>d.latitude && d.longitude);
    
    return records;
}
var pi = Math.PI;

function d2rad(degrees:number)
{
  return degrees * (pi/180);
}

let toDegrees = (radians:number) => radians * (180/pi);

const lastDataMap:any[]=[]


function calcBearing (lat1:number, long1:number, lat2:number, long2:number){
    let dLon = (long2 - long1)
    let x = Math.cos(d2rad(lat2)) * Math.sin(d2rad(dLon))
    let y = Math.cos(d2rad(lat1)) * Math.sin(d2rad(lat2)) - Math.sin(d2rad(lat1)) * Math.cos(d2rad(lat2)) * Math.cos(d2rad(dLon))
    let bearing = Math.atan2(x,y);
    bearing = toDegrees(bearing)

    return bearing
}


  useEffect(() => {
    
    timer.id++;

    data && data.forEach(nd=>{
        lastDataMap[nd.id]=nd;
    })
    fetchDataOC(0,null)
      .then(newData => {

        x++;
        // newData.forEach(n=>{
        //     n.latitude= n.latitude+(x*0.0001);
        //     n.longitude= n.longitude+(x*0.0001);

        // })

        if(x==1)
        setInitialViewState({...initialViewState,latitude:newData[0].latitude,longitude:newData[0].longitude})


       

        if (timer.id === null) {
          // Component has unmounted
          return;
        }
        // In order to keep the animation smooth we need to always return the same
        // object at a given index. This function will discard new objects
        // and only update existing ones.
        // if (data) {
        //   const dataById: Record<string, any> = {};
        //   newData.forEach(entry => (dataById[entry.id] = entry));
        //   newData = data.map(entry => dataById[entry.id] || entry);
        // }

        newData.forEach(nd=>{

            let ld = lastDataMap[nd.id];
            if(ld){
                const bearing = calcBearing(ld.latitude,ld.longitude,nd.latitude,nd.longitude)
                nd['bearing']=bearing;
            }
        })

        setData(newData);

        if (onDataLoad) {
          onDataLoad(newData.length);
        }
      })
      .finally(() => {
       const timeoutId = setTimeout(() => setTimer({id: timeoutId}), 30 * 1000);
       timer.id = timeoutId;
      });

    return () => {
      clearTimeout(timer.id);
      timer.id = null;
    };
  }, [timer]);

  const layer = new ScenegraphLayer<any>({
    id: 'scenegraph-layer',
    data,
    pickable: true,
    sizeScale,
    sizeMinPixels: 0.1,
    sizeMaxPixels: 2,
    scenegraph: '/Bus4glb.glb',
    _animations: {
        '*': {speed: 100}
      },
      _lighting: 'pbr',

      
    

    getPosition: (d:any) => [
      d.longitude ?? 0,
      d.latitude ?? 0,
      d.altitude ?? 0
    ],
    getOrientation: (d:any) => {
        if(d){
      const verticalRate = 0;// d[DATA_INDEX.VERTICAL_RATE] ?? 0;
      const velocity =0;// d[DATA_INDEX.VELOCITY] ?? 0;
      // -90 looking up, +90 looking down
      const pitch = (-Math.atan2(verticalRate, velocity) * 180) / Math.PI;
      const yaw =0;// -d[DATA_INDEX.TRUE_TRACK];
      return [0, d.bearing||10, 90];
        }else{
            return [0, 0, 0];
        }
    },
    getScale: d => {
     /// const lastContact = d[DATA_INDEX.LAST_CONTACT];
      //return lastContact < -DROP_IF_OLDER_THAN_SECONDS ? [0, 0, 0] : [1, 1, 1];
      return [1, 1, 1];
    },
    transitions: {
      getPosition: 1 * 1000
    }
  });

  return (
    <DeckGL
      layers={[layer]}
      initialViewState={initialViewState}
      controller={true}
      getTooltip={getTooltip as any}
    >
      <Map reuseMaps mapStyle={mapStyle} />
    </DeckGL>
  );
}
