import React from 'react';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { FaHeart, FaBars, FaSave, FaCheck } from 'react-icons/fa';
import reactLogo from './images/logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';


const Profile = ({
}) => {
  const intl = useIntl();
  return (
    <main style={{marginTop:"70px"}}>
    
      <header>

      <Row>
        <Col sm={6}>
        <h1>
          <img width={80} src={reactLogo} alt="react logo" /> {intl.formatMessage({ id: 'title' })}
        </h1>
        <p>{intl.formatMessage({ id: 'description' })}</p>

        </Col>
        <Col sm={6}>
            <Button  variant="primary" type="submit" style={{float:"right"}}>
                <FaCheck></FaCheck>  Save
            </Button>
        </Col>

        
        
        </Row>
        
      </header>
      <div className="block ">


      <div style={{ display: 'block', 
                  width: "100%",
                  height:(window.innerHeight-74)+"px",
                  padding: 10 }}>

      <Form>
     
<Container fluid>
      <Row>
        <Col sm={6}>


        <Row>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control 
                        placeholder="" />
        </Form.Group>
        </Col>
        </Row>

        <Row>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" 
                        placeholder="" />
        </Form.Group>
        </Col>
        </Row>

        <Form.Group>
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control placeholder="" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control placeholder="" />
        </Form.Group>
        <Row>

        <Col sm={6}>
        <Form.Group>
          <Form.Label>City</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>State</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        
        </Row>

        <Row>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>Postal/ZIP Code</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        <Col sm={6}>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Control  
                        placeholder="" />
        </Form.Group>
        </Col>
        
        </Row>
        

       
        </Col>
        <Col sm={6} style={{background:"#efefef" }}>
       

        


        </Col>
      </Row>
    </Container>
     
        
      </Form>
    </div>
      </div>

      <footer>
        
      </footer>
      

    </main>
  );
};

export default Profile;