/*global google*/
import WrappedMap from "./Map";
import { useState } from "react";
import { FaMapMarker, FaSearch, FaSearchLocation } from "react-icons/fa";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    IconButton,
    ModalCloseButton,
    useDisclosure,
    Button
} from '@chakra-ui/react'
import React from "react";


export default function LocationInput({className="w-auto",type="address",onChange=(info)=>{},location={}}) {

    const [selectedItem, setSelectedItem] = useState({})

    console.log(location)

    const [address, setAddress] = useState();

    const onAddress = (address) => {
        if(type=="address"){
            setAddress(address.address)
        }else{
            setAddress(address.location.lat+","+address.location.lng)
        }
        onChange(address)
    }


    function MarkerButton({ location, onAddress }) {
        const { isOpen, onOpen, onClose } = useDisclosure()
        const [center, setCenter] = useState(location)
        const [query, setQuery] = useState()

        const [mapCenter, setMapCenter] = useState(location)

        const [locationInfo, setLocationInfo] = useState({})


        const getAddress = async () => {

            if(type!="address"){
                onAddress({location:center.toJSON()})
            }else{
                const geocoder = new google.maps.Geocoder();

                const response = await geocoder
                    .geocode({ location: center })

                onAddress({address: response.results[0].formatted_address,location:center})
            }

        }

        const onLocationChanged = (center) => {
            setCenter(center);
            setLocationInfo({location:center.toJSON()})

        }

        const serachAddress = () => {
            const qs = (query.split(","));
            if(qs.length==2 && !isNaN(qs[0])){
                const loc = {
                    lat:+qs[0],
                    lng:+qs[1]
                }
                setCenter(loc)
                setMapCenter(loc)
            }else{
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode({ 'address': query }, function (results, status) {
                    if (results && status == google.maps.GeocoderStatus.OK) {
                        setCenter(results[0].geometry.location.toJSON())
                        setMapCenter(results[0].geometry.location.toJSON())
                        console.log(results[0].geometry.location.toJSON())
                        setLocationInfo({address: results[0].formatted_address,location:results[0].geometry.location.toJSON()})
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        }


        return (
            <>
                <IconButton rounded="full" aria-label="" icon={<FaMapMarker color="green"></FaMapMarker>} onClick={onOpen}></IconButton>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Search Location</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody minHeight={"300px"} width={"full"}>
                            <div className="flex bg-teal-500 w-full p-1 relative">
                                <input className="flex-1 p-0 mt-0 " value={query} onChange={(e) => { setQuery(e.target.value) }}></input>
                                <IconButton aria-label="" rounded={"full"}  className=" mt-0 ml-1  " icon={ <FaSearchLocation ></FaSearchLocation>} onClick={() => serachAddress()}></IconButton>
                            </div>
                            <WrappedMap
                                location={center}
                                mapCenter={mapCenter}
                                onLocationChanged={onLocationChanged}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=&libraries=places&v=weekly`}
                                loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
                                containerElement={<div style={{ height: `300px`, width: "100%" }} />}
                                mapElement={<div style={{ height: `100%`, width: "100%" }} />}
                            />
                        </ModalBody>

                        <ModalFooter>
                           
                            <Button colorScheme='blue' onClick={()=>locationInfo.address?onAddress(locationInfo.address):getAddress()}>Continue</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    console.log("location => "+JSON.stringify(location))

    return <>





                <div className={`flex items-center flex-wrap  p-1 gap-1 ${className}`}>
                    {/* <input className="p-0 mt-0" value={address} onChange={(e) => { setSelectedItem({ value: e.target.value }) }}></input> */}
                    <MarkerButton  onAddress={onAddress} location={location}></MarkerButton>

                </div>

    </>
}