import React, { useState, useEffect } from "react";
import { DeckGL } from "@deck.gl/react";
import { Map as StaticMap } from "react-map-gl";
import { IconLayer } from "@deck.gl/layers";

const MAPBOX_TOKEN = "pk.eyJ1IjoiZGhhbmVlc2h0bmFpciIsImEiOiJjajN2OXU3b2IwMTRuMnlsYmRhandwdWV0In0.PhXxSGpmy4fXeO4tbfQ9Yg"; // Replace with your Mapbox token

const MovingCarsMap = () => {
  const [carPositionIndex, setCarPositionIndex] = useState(0);

  // Define the path for the car
  const carPath = [
    [-122.399, 37.791],
    [-122.398, 37.792],
    [-122.397, 37.793],
    [-122.396, 37.794],
  ];

  // Get the current position of the car
  const getCarPosition = () => carPath[carPositionIndex % carPath.length];

  // Update the car position index every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCarPositionIndex((prevIndex) => prevIndex + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // IconLayer for the car
  const carLayer = new IconLayer({
    id: "car-layer",
    data: [{ position: getCarPosition(), icon: "car" }],
    pickable: true,
    iconAtlas: "/logo192.png", // Public domain car icon
    iconMapping: {
      car: { x: 0, y: 0, width: 512, height: 512, anchorY: 512 },
    },
    getIcon: (d) => "car",
    sizeScale: 15,
    getPosition: (d) => d.position,
  });

  // Initial view state for Deck.gl
  const INITIAL_VIEW_STATE = {
    longitude: -122.4,
    latitude: 37.8,
    zoom: 13,
    pitch: 45,
    bearing: 0,
  };

  return (
    <DeckGL
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      layers={[carLayer]}
      style={{ width: "100vw", height: "100vh" }}
    >
      <StaticMap
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/light-v10"
      />
    </DeckGL>
  );
};

export default MovingCarsMap;
