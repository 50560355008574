import React, { useRef, useState, useCallback } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker
} from "react-google-maps";

function Map({location,mapCenter,onLocationChanged}) {
  const refMap = useRef(null);

  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter(); //get map center
   // console.log(refMap.current.marker)
   if(mapCenter)
   onLocationChanged(mapCenter);
  };
  
  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={13}
      defaultCenter={location}
      center={mapCenter} 

      onBoundsChanged={handleBoundsChanged}
      
    >
      <Marker position={location} />
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(Map));
