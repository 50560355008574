
import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import logo from './images/apple.png'
import './marker.css'

let mapObject = null;
export default function SimpleMap({record}){
  const [location,setLocation]=useState( {
    lat: record.latitude, lng: record.longitude 
  })
  const defaultProps = {
    center: {
      lat: record.latitude, lng: record.longitude 
    },
    zoom: 14
  };

  useEffect(() => {
    setLocation({
      lat: record.latitude, lng: record.longitude 
    })
  }, [record.id])

  const AnyReactComponent = ({ text }) =>{

    // if(mapObject){
    //   mapObject.panTo(location);
    //   console.log("Pan to");
    // }
    return <div className={"customMarker"}><img src={record.driverImageURL}></img></div>;
  }

  
  const renderMarkers = (map, maps) => {
    // let marker = new maps.Marker({
    // position: { lat: record.latitude, lng: record.longitude },
    // map,
    // title: 'Hello World!'
    // });
    // return marker;
    mapObject = map;
    // let marker = new maps.Marker({
    //   position: { lat: record.latitude, lng: record.longitude },
    //   map,
    //   title: 'Hello World!'
    //   });
    //   return marker;
   // return;
   };
//   https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=AIzaSyCDLnYxjcfIk7t-EMbNImqNZZuHp0-Cp5M&callback=initAutocomplete
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCDLnYxjcfIk7t-EMbNImqNZZuHp0-Cp5M" }}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      

      >
        <AnyReactComponent
          lat={record.latitude}
          lng={record.longitude}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
}