import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom2.css';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


export default function Header({logout}) {
  

	function readCurrentState(){
		if(window.location.pathname.indexOf("/dashboard")!=-1 || window.location.pathname=="/"){
			return "r";
		}else if(window.location.pathname.indexOf("/clusters")!=-1){
			return "cl";
		}else if(window.location.pathname.indexOf("/downloads")!=-1){
			return "dl";
		}else{
			return 'r';
		}
	}
	const [selected,setSelected] =useState(readCurrentState());
	const [logoff,setLogoff] =useState(false);
	

	var userInfo = localStorage.getItem("userInfo");
	if(userInfo){
	  userInfo = JSON.parse(userInfo);
	}





  return (
    <header className="Home_titleBar__3XDTs">
		<a href="/" className="Home_title__1dfd5">
			<span className="Home_titlePrefix__vIot9">IDRider</span>&nbsp;&nbsp;
		</a>
		{/* <nav className="titlebar_mainlinks">
			<a className={selected=='r'?"Home_link__3O3aj activeLink":"Home_link__3O3aj"} href="#" onClick={e=>{setSelected('r');window.history.pushState('', 'New Page Title', '/');}}>Dahsboard</a>
			<a className={selected=='cl'?"Home_link__3O3aj activeLink":"Home_link__3O3aj"} href="#" onClick={e=>{setSelected('cl');window.history.pushState('', 'New Page Title', '/clusters');}}>Clusters</a>
			<a className={selected=='dl'?"Home_link__3O3aj activeLink":"Home_link__3O3aj"} href="#" onClick={e=>{setSelected('dl');window.history.pushState('', 'Downloads', '/downloads');}}>Downloads</a>

		</nav> */}
		{
			userInfo &&
		<a className="Home_link__3O3ajgithub" href="#" onClick={(e)=>setLogoff(true)}
			rel="noopener noreferrer">
			<div>{userInfo.username+"@"+userInfo.partnerId}</div>
			
		</a>
		}
		{/* <a className="Home_link__3O3ajgithub" href="https://github.com" target="_blank" rel="noopener noreferrer">
			<div>GitHub</div>
			
		</a> */}
		{ logoff && 
		<SweetAlert style={{color:"black"}}
	warning
	showCancel
	confirmBtnText="Yes, Proceed!"
	confirmBtnBsStyle="danger"
	title="Are you sure?"
	onConfirm={()=>{setLogoff(false);logout()}}
	onCancel={()=>{setLogoff(false)}}
	focusCancelBtn
  >
	
  </SweetAlert>

		}
	</header>
	
  );
}

