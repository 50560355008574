import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import OneDRideAccountForm from "./OneDRideAccountForm";
import OneDRideAccountsTable from "./table/OneDRideAccountsTable";

export const OneDRideAccounts=()=>{

    const [record,setRecord]=useState(null);
    const [value, setValue] = useState(new Date().getTime());


    const updateRecord=(rec)=>{
        setRecord(rec)
    }

    function reloadComponent(){
        setRecord(null)
        setValue(new Date().getTime());
    }

    return(


        <div style={{height:"100%"}}>
            {value &&

<Container fluid>
     


      <div className="flex ">
        <div className={record?`w-9/12`:"flex-1"}>
        <OneDRideAccountsTable onSelectRecord={updateRecord} updateTime={value}/>        </div>
        {
           record && <div className="shadow-xl" sm={3} style={{background:"#112233",position:"fixed",right:"0px",overflowY:"auto" }}><OneDRideAccountForm onSelectRecord={updateRecord} record={record} refresh={reloadComponent}/></div>
        }
      </div>
    </Container>
            
    }
            </div>
    
    );

}