import {
    Box,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    VStack,
  } from '@chakra-ui/react'
  
  interface Props {
    onClose: any
    isOpen: boolean
    variant: 'drawer' | 'sidebar',
    selectedItem:any
  }
  
  const SidebarContent = ({ onClick,selectedItem }: { onClick: any,selectedItem:any }) => (
    <VStack>
         {selectedItem &&

<div className='flex gap-2'>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}} src={selectedItem.vehicleImageURL}></img>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}}  src={selectedItem.driverImageURL}></img>
</div>

}
      {selectedItem &&
        Object.keys(selectedItem).filter(k=>k=="speed"||k=="name"||k=="phone"||k=="subsStatus"||k=="operationStatus"||k=="vehicleType"||k=="vehicleModel"||k=="vehicleNumber").map(k=>{
            return <div style={{background:"#000000a0"}} className='p-1 flex w-full text-white items-center justify-start'><span className='w-1/2'>{k.toUpperCase()}</span><span>{k!="speed"?selectedItem[k]:selectedItem[k]+" km/hour"}</span></div>
        })
      }


{selectedItem &&

<div style={{background:"#000000a0"}} className='p-1 flex w-full text-white items-center justify-start'><span className='w-1/2'>{"LAST UPDATED TIME"}</span><span>{new Date(selectedItem.lastAccessDate).toLocaleString()}</span></div>

}




     
    </VStack>
  )
  
  const Sidebar = ({ isOpen, variant, onClose,selectedItem }: Props) => {
    return variant === 'sidebar' ? (
      <Box
        position="fixed"
        left={0}
        p={5}
        w="300px"
        top={0}
        h="100%"
        bg="#dfdfdf"
      >
        <SidebarContent selectedItem={selectedItem} onClick={onClose} />
      </Box>
    ) : (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"sm"}  >
        <DrawerOverlay>
          <DrawerContent style={{background:"#728191"} }>
            <DrawerCloseButton />
            <DrawerHeader>Vehicle Details</DrawerHeader>
            <DrawerBody>
              <SidebarContent selectedItem={selectedItem} onClick={onClose} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }
  
  export default Sidebar
  