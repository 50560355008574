import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaCogs, FaUser, FaQuestion, FaBus, FaCar, FaAtlas, FaRegistered } from 'react-icons/fa';
import sidebarBg from './images/bg2.jpeg';

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar,state,setstate }) => {
  const intl = useIntl();
  //const [state, setstate] = useState("dashboard")
  

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {intl.formatMessage({ id: 'sidebarTitle' })}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          {/* <MenuItem active={state == "dashboard"} 
            onClick={() => setstate('dashboard')} 
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >
            {intl.formatMessage({ id: 'dashboard' })}
          </MenuItem> */}
          <MenuItem active={state == "components"} onClick={() => setstate('components')}  icon={<FaQuestion />}> {intl.formatMessage({ id: '1DRide Requests' })}</MenuItem>
          <MenuItem active={state == "1DRideAccounts"} onClick={() => setstate('1DRideAccounts')}  icon={<FaCar />}> {intl.formatMessage({ id: '1DRide Accounts' })}</MenuItem>
        </Menu>

        

        {/* <Menu iconShape="circle">
          <SubMenu
            suffix={<span className="badge yellow">3</span>}
            title="Manage Accounts"
            icon={<FaUser />}
          >
            <MenuItem active={state == "profile"} onClick={() => setstate('profile')}>Update Profile</MenuItem>
            <MenuItem active={state == "changePassword"} onClick={() => setstate('changePassword')}>Change Password</MenuItem>

          </SubMenu>
           <SubMenu
            prefix={<span className="badge gray">3</span>}
            title={intl.formatMessage({ id: 'withPrefix' })}
            icon={<FaHeart />}
          >
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 1</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 2</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3</MenuItem>
          </SubMenu>
          <SubMenu title={intl.formatMessage({ id: 'multiLevel' })} icon={<FaList />}>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 1 </MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 2 </MenuItem>
            <SubMenu title={`${intl.formatMessage({ id: 'submenu' })} 3`}>
              <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.1 </MenuItem>
              <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.2 </MenuItem>
              <SubMenu title={`${intl.formatMessage({ id: 'submenu' })} 3.3`}>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.1 </MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.2 </MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.3 </MenuItem>
              </SubMenu>
            </SubMenu>
          </SubMenu> 


        </Menu> */}

        {/* <Menu iconShape="circle">
          <MenuItem
          active={state == "settings"}
            onClick={() => setstate('settings')} 
            icon={<FaCogs />}
            suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >
            {intl.formatMessage({ id: 'settings' })}
          </MenuItem>
        </Menu> */}


      <Menu iconShape="circle">
          <MenuItem
          active={state == "settings"}
            onClick={() => {window.open("https://www.1dride.com/registration.html","_blank")}} 
            icon={<FaRegistered />}
            suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >
            New Registration 
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://www.1drider.com"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <FaAtlas />
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              1Dride.com
            </span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;