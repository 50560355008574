import { FaPen } from 'react-icons/fa';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import './style.min.css';
import React from 'react';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { FaHeart, FaBars, FaSave, FaCheck } from 'react-icons/fa';
import reactLogo from './images/logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from "react-bootstrap/Card";


export default function Timeline({ record }) {
    const intl = useIntl();

    return (


        <main style={{ marginTop: "0px" }}>

            <header>

                <Row>

                    <Col sm={12}>
                        <Button variant="primary" type="submit" style={{ float: "right" }}>
                            <FaCheck></FaCheck>  Save
              </Button>
                    </Col>



                </Row>

            </header>
            <div className="block ">


                <div style={{
                    display: 'block',
                    width: "100%",
                    height: (window.innerHeight - 74) + "px",
                    padding: 10
                }}>


                    <Form>

                        <Container fluid>
                            <Row>
                                <Col sm={9}>


                                <Card style={{width:"100%"}}>
          <Card.Body>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email"
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control placeholder="" />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control placeholder="" />
                                    </Form.Group>
                                    <Row>

                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Postal/ZIP Code</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    placeholder="" />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    </Card.Body>
                                    </Card>



                                </Col>
                                <Col sm={3} style={{ background: "#efefef" }}>

                                    <VerticalTimeline>
                                       
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            date="2010 - 2011"
                                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Art Director</h3>
                                            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                                            <p>
                                                Creative Direction, User Experience, Visual Design, SEO, Online Marketing
          </p>
                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            date="2008 - 2010"
                                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Web Designer</h3>
                                            <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                                            <p>
                                                User Experience, Visual Design
          </p>
                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            date="2006 - 2008"
                                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Web Designer</h3>
                                            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                                            <p>
                                                User Experience, Visual Design
          </p>
                                        </VerticalTimelineElement>
                                        {/* <VerticalTimelineElement
                                            className="vertical-timeline-element--education"
                                            date="April 2013"
                                            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
                                            <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
                                            <p>
                                                Strategy, Social Media
          </p>
                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--education"
                                            date="November 2012"
                                            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                                            <h4 className="vertical-timeline-element-subtitle">Certification</h4>
                                            <p>
                                                Creative Direction, User Experience, Visual Design
          </p>
                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--education"
                                            date="2002 - 2006"
                                            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                                            icon={<FaPen />}
                                        >
                                            <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                                            <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                                            <p>
                                                Creative Direction, Visual Design
          </p>
                                        </VerticalTimelineElement> */}

                                    </VerticalTimeline>




                                </Col>
                            </Row>
                        </Container>


                    </Form>
                </div>
            </div>

            <footer>

            </footer>


        </main>

    );
}



