import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './Layout';
import { IntlProvider } from 'react-intl';
import messages from './messages';
import './styles/App.scss';

export default function Dashboard() {


  var userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }
  const [locale, setLocale] = useState('en');

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Layout setLocale={setLocale} />
    </IntlProvider>
  );
}

